
import { defineComponent, inject, reactive, ref, computed, ComputedRef, onBeforeMount } from 'vue'
import {IonPage, IonContent} from '@ionic/vue'
import { useRouter } from 'vue-router';
import Swal from 'sweetalert2';
import { useI18n } from "vue-i18n";
import i18n from '@/i18n';


export default defineComponent({
    components: {IonPage, IonContent},
    setup() {
        const authStore: any = inject('authState');
        const configurationStore: any = inject('configurationStore');
        const addMobileFormData = reactive(configurationStore.state.phone.addMobileFormData);
        const newMobileIsSelected = ref(false);
        const validPrefixList = computed(() => authStore.state.validPrefixList)
        const router: any     = useRouter( )
        const goTo = (url: any) =>{
            router.push( { name: url } );
        }
        onBeforeMount(async () => {
            await authStore.obtainCountry()
            setPrefixDefault();
        })
        const newMobileFormSubmit = async() => {
            configurationStore.state.phone.addMobileFormData.showSuccess = false;
            configurationStore.state.phone.addMobileFormData.showError = false;
            if (configurationStore.state.phone.addMobileFormData.number.length != 9) {
                configurationStore.state.phone.addMobileFormData.showError = true;
                configurationStore.state.phone.addMobileFormData.errorMessage = 'tester_mobile_not_valid';
            }
            const data = {
                number: configurationStore.state.phone.addMobileFormData.number,
                prefix: configurationStore.state.phone.addMobileFormData.prefix
            }
            const success = await configurationStore.addMobile(data);
            if(success && !configurationStore.state.phone.addMobileFormData.showError){
                Swal.fire({
                    icon: 'success',
                    title: i18n.global.t('tester_mobile_validated'),
                    text: i18n.global.t('tester_email_validate_mobile_now'),
                    showConfirmButton: true,
                    confirmButtonText: i18n.global.t('Accept'),
                }).then((result)=>{
                    if (result.isConfirmed || result.dismiss === Swal.DismissReason.esc || result.dismiss === Swal.DismissReason.backdrop) {
                        redirect();
                    }
                })
            }
        }
        const redirect = async () => {
            goTo('verify-mobile')
        }
        const setErrorMessage = (errorCode: any) => {
            switch (errorCode) {
                case 'tester_mobile_not_valid':
                    return i18n.global.t('tester_mobile_incorrect');
                case 'registration_mobile_fraud':
                    return i18n.global.t('tester_duplicate_mobile_error');
                case 'default_error_message':
                default:
                    return i18n.global.t('tester_default_error');
            }
        }
        const setPrefixDefault = async() => {
            addMobileFormData.prefix = authStore.state.userData.mobilePrefix
        }
        const addMobileFormValid: ComputedRef<boolean> = computed(
            (): boolean => addMobileFormData.prefix && addMobileFormData.number
        )
        return{
            addMobileFormData,
            newMobileIsSelected,
            newMobileFormSubmit,
            goTo,
            validPrefixList,
            addMobileFormValid,
            setErrorMessage,
            redirect
        }
    },
})
